import Builder from './Swiper/Builder.es6';
import Dispatcher from './Swiper/Dispatcher.es6';

class Swiper {

    options = {
        itemAlignment: {
            height: 'height',
            width: 'width'
        },
        itemAlignmentItemDefault: {
            height: {
                height: 350,
                width: 'auto'
            },
            width: {
                height: 'auto',
                width: 350
            }
        },
    };

    parent = null;
    selectedIndex = null;
    visibleIndexes = [];
    items = [];
    domObj = null;
    draggableObj = null;
    navigationInfinity = true;
    navigationReverse = true;
    navigationScrolling = true;
    navigationButtons = true;
    itemAlignment = 'height';
    itemHeight = null;
    itemWidth = null;
    itemImageSize = 100;
    itemImageFit =  false;
    onSelect = null;
    onClick = null;
    autoplay = 0;

    builder = null;
    dispatcher = null;

    constructor({parent, selectedIndex, visibleIndexes, items, navigationInfinity, navigationReverse, navigationScrolling, navigationButtons, itemAlignment, itemHeight, itemWidth, itemImageSize, itemImageFit, autoplay}) {

        this.parent = typeof parent === "string" ? cl.byId(parent) : parent;
        this.selectedIndex = selectedIndex === undefined ? this.selectedIndex : selectedIndex;
        this.visibleIndexes = visibleIndexes === undefined ? this.visibleIndexes : visibleIndexes;
        this.items = items === undefined ? this.items : items;
        this.navigationInfinity = navigationInfinity === undefined ? this.navigationInfinity : navigationInfinity;
        this.navigationReverse = navigationReverse === undefined ? this.navigationReverse : navigationReverse;
        this.navigationScrolling = navigationScrolling  === undefined ? this.navigationScrolling : navigationScrolling;
        this.navigationButtons = navigationButtons === undefined ? this.navigationButtons : navigationButtons;
        this.itemAlignment = itemAlignment === undefined ? this.itemAlignment : itemAlignment;
        this.itemHeight = itemHeight === undefined ? this.itemHeight : itemHeight;
        this.itemWidth = itemWidth === undefined ? this.itemWidth : itemWidth;
        this.itemImageSize = itemImageSize === undefined ? this.itemImageSize : itemImageSize;
        this.itemImageFit = itemImageFit === undefined ? this.itemImageFit : itemImageFit;
        this.autoplay = autoplay === undefined ? this.autoplay : autoplay;

        this.updateDraggableObjectWidth = () => {
            this.dispatcher.updateDraggableObjectWidth();
        };

        this.getSelectedTranslationX = () => {
            return this.dispatcher.getSelectedTranslationX();
        };

        this.getSelectedItemWidth = () => {
            return this.dispatcher.getSelectedItemWidth();
        };

        this.getX = (_event) => {
            return this.dispatcher.getX(_event);
        };

        this.getRelativeX = (_event) => {
            return this.dispatcher.getRelativeX(_event);
        };

        this.getMaxTranslationX = () => {
            return this.dispatcher.getMaxTranslationX();
        };

        this.getMinTranslationX = () => {
            return this.dispatcher.getMinTranslationX();
        };

        this.getMaxIndex = () => {
            return this.dispatcher.getMaxIndex();
        };

        this.getMinIndex = () => {
            return this.dispatcher.getMinIndex();
        };

        this.getMinScope = () => {
            return this.dispatcher.getMinScope();
        };

        this.getMaxScope = () => {
            return this.dispatcher.getMaxScope();
        };

        this.getSelectedIndex = () => {
            return this.dispatcher.getSelectedIndex();
        };

        this.getVisibleIndexes = () => {
            return this.dispatcher.getVisibleIndexes();
        };

        this.startDragDrop = (e) => {
            this.dispatcher.startDragDrop(e);
        };

        this.endDragDrop = (e) => {
            this.dispatcher.endDragDrop(e);
        };

        this.moveDragDrop = (e) => {
            this.dispatcher.moveDragDrop(e);
            this.updatingDom();
        };

        this.startHorizontalScroll = (e) => {

            if (this.navigationScrolling) {
                this.dispatcher.startHorizontalScroll(e);
            }
        };

        this.endHorizontalScroll = (e) => {

            this.dispatcher.endHorizontalScroll(e);
        };

        this.disableAutoPlay = (e) => {

            this.dispatcher.disableAutoPlay(e);
        };

        this.enableAutoPlay = (e) => {

            this.dispatcher.enableAutoPlay(e);
        };

        this.timer = null;

        this.moveHorizontalScroll = (e) => {

            this.dispatcher.moveHorizontalScroll(e);
        };

        this.startDragDropTouch = cl.event.convertTouchDownToMouseDown(false);

        this.moveDragDropTouch = cl.event.convertTouchMoveToMouseMove(false);

        if (this.selectedIndex === null) {
            this.selectedIndex = Math.round((this.items.length - 1) / 2);
        }

        this.resizeTimer = null;
        this.resizeWidth = null;

        cl.event.addListener(window, 'resize', () => {

            if (!this.domObj || this.resizeWidth === this.domObj.offsetWidth) return;

            if (this.resizeTimer) {
                clearTimeout(this.resizeTimer);
            }

            this.resizeTimer = setTimeout( () => {
                this.prepareItemSizes();
                this.builder.buildSwiper();
                this.prepareDraggableEventListener();
                cl.loader.loadVisibleElements();

                this.resizeWidth = this.domObj.offsetWidth;
            }, 500);
        });

        this.builder = new Builder(this);
        this.dispatcher = new Dispatcher(this);

        this.prepareItemSizes();
        this.prepareDom();
        this.prepareEventListener();

        if (this.autoplay > 0) {
            this.enableAutoPlay();
        }
    }

    setLoaded() {

        if (this.items.length > 0) {
            this.scrollTo(this.selectedIndex);
        }

        cl.basic.addClassName(this.domObj, 'loaded');
    };

    /*
    updateDraggableObjectWidth() {
        this.dispatcher.updateDraggableObjectWidth();
    };

    getSelectedTranslationX() {
        return this.dispatcher.getSelectedTranslationX();
    };

    getSelectedItemWidth() {
        return this.dispatcher.getSelectedItemWidth();
    };

    getX(_event) {
        return this.dispatcher.getX(_event);
    };

    getRelativeX(_event) {
        return this.dispatcher.getRelativeX(_event);
    };

    getMaxTranslationX() {
        return this.dispatcher.getMaxTranslationX();
    };

    getMinTranslationX() {
        return this.dispatcher.getMinTranslationX();
    };

    getMaxIndex() {
        return this.dispatcher.getMaxIndex();
    };

    getMinIndex() {
        return this.dispatcher.getMinIndex();
    };

    getMinScope() {
        return this.dispatcher.getMinScope();
    };

    getMaxScope() {
        return this.dispatcher.getMaxScope();
    };

    getSelectedIndex() {
        return this.dispatcher.getSelectedIndex();
    };

    getVisibleIndexes() {
        return this.dispatcher.getVisibleIndexes();
    };

    startDragDrop(e) {
        this.dispatcher.startDragDrop(e);
    };

    endDragDrop(e) {
        this.dispatcher.endDragDrop(e);
    };

    moveDragDrop(e){
        this.dispatcher.moveDragDrop(e);
    }

    startHorizontalScroll(e) {

        if (this.navigationScrolling) {
            this.dispatcher.startHorizontalScroll(e);
        }
    }

    endHorizontalScroll(e) {

        this.dispatcher.endHorizontalScroll(e);
    }

    disableAutoPlay(e) {

        this.dispatcher.disableAutoPlay(e);
    }

    enableAutoPlay(e) {

        this.dispatcher.enableAutoPlay(e);
    }

    moveHorizontalScroll(e) {

        this.dispatcher.moveHorizontalScroll(e);
    };

    */

    prepareItemSizes()
    {
        this.prpearedItemHeight = this.itemHeight;
        this.prpearedItemWidth = this.itemWidth;

        if(this.prpearedItemHeight === null)
        {
            this.prpearedItemHeight = this.options.itemAlignmentItemDefault[this.itemAlignment].height;
        }

        if(this.prpearedItemWidth === null)
        {
            this.prpearedItemWidth = this.options.itemAlignmentItemDefault[this.itemAlignment].width;
        }

        if(typeof this.prpearedItemHeight === "number" && this.prpearedItemHeight <= 1)
        {
            this.prpearedItemHeight = Math.round(this.parent.offsetHeight * this.itemHeight);
        }

        if(typeof this.prpearedItemWidth === "number" && this.prpearedItemWidth <= 1 && this.parent != null)
        {
            this.prpearedItemWidth = Math.round(this.parent.offsetWidth * this.itemWidth);
        }
    };

    scrollTo(_index) {

        if (_index < this.getMinIndex()) {
            _index = this.getMinIndex();
        } else if (_index > this.getMaxIndex()) {
            _index = this.getMaxIndex();
        }

        if (_index !== undefined && this.selectedIndex !== _index) {
            this.selectedIndex = _index;
        }

        const x = this.getSelectedTranslationX();

        if (this.draggableObj !== undefined && this.draggableObj !== null) {
            this.draggableObj.style.transform = 'translateX(' + x + 'px)';
            this.draggableObj.setAttribute('data-transform-x', x);
        }

        setTimeout(() => {
            this.visibleIndexes = this.getVisibleIndexes();
            this.updatingDom();
        }, 150);
    };

    prepareDom() {

        const wrapper = this.parent, items = [];
        let i, ii, count, item;

        if (wrapper)
        {
            this.domObj = cl.basic.createDomElement({
                tagName: 'div',
                className: 'swiper-wrapper',
                'data-item-alignment': this.itemAlignment
            });

            if(wrapper.children.length > 0)
            {
                while (wrapper.children.length > 0)
                {
                    item = wrapper.children[0];

                    items.push({
                        domObj: cl.basic.createDomElement({
                            tagName: 'div',
                            className: 'item',
                            children: [item],
                            'data-alignment': this.itemAlignment
                        })
                    });
                }

                if(!document.body.dataset.editor && this.navigationInfinity)
                {
                    count = items.length;
                    const infinityCounter = 6;

                    for (i = infinityCounter; i > 0; --i)
                    {
                        for (ii = 0; ii < count; ++ii)
                        {
                            item = items[ii].domObj.cloneNode(true);

                            cl.basic.removeClassName(item, 'active');

                            items.push({
                                domObj: item
                            });
                        }
                    }

                    this.selectedIndex = this.selectedIndex + (items.length - (count * (infinityCounter/2 + 1)));
                }

                if(items[this.selectedIndex] === undefined)
                {
                    this.selectedIndex = 0;
                }

                cl.basic.addClassName(items[this.selectedIndex].domObj, 'active');

                this.items = items;

                wrapper.appendChild(this.domObj);

                this.resizeWidth = this.domObj.offsetWidth;
                this.builder.buildSwiper();
            }
        }
    }

    prepareEventListener() {

        if(this.autoplay > 0)
        {
            cl.event.addListener(this.domObj, 'mouseover',  (e) => this.disableAutoPlay(e));
            cl.event.addListener(this.domObj, 'mouseleave', (e) => this.enableAutoPlay(e));
        }

        this.prepareDraggableEventListener();
    }

    prepareDraggableEventListener() {

        if(this.draggableObj)
        {
            cl.event.addListener(this.draggableObj, 'mouseover',  (e) => this.startHorizontalScroll(e));
            cl.event.addListener(this.draggableObj, 'mousedown', (e) =>  this.startDragDrop(e));
            cl.event.addListener(this.draggableObj, 'touchstart',(e) => this.startDragDropTouch(e), {passive: true});

            cl.basic.addClassName(this.draggableObj, 'draggable-events');
        }
    }

    verifyUpdatedData(_data)
    {
        if(_data.selectedIndex >= _data.items.length)
        {
            _data.selectedIndex = _data.items.length - 1;
        }
        else if(_data.selectedIndex < 0)
        {
            _data.selectedIndex = 0;
        }

        return _data;
    }

    updatingDom() {

        let key;
        const items = this.items;

        const visibleElements = cl.basic.getElementsByClassName('in-view', this.parent);

        for(key in visibleElements)
        {
            cl.basic.removeClassName(visibleElements[key], 'in-view');
            cl.basic.removeClassName(visibleElements[key], 'in-full-view');
            cl.basic.removeClassName(visibleElements[key], 'in-partial-view');

            delete visibleElements[key].dataset.view;
        }

        for(key in this.visibleIndexes)
        {
            cl.basic.addClassName(items[key].domObj, 'in-view ' + (this.visibleIndexes[key] === 100 ? 'in-full-view' : 'in-partial-view'));

            items[key].domObj.dataset.view = this.visibleIndexes[key];
        }

        this.afterUpdatingDom();
    }

    afterUpdatingDom() {

        if(typeof this.onSelect === 'function')
        {
            this.onSelect(this.selectedIndex);
        }
    }
}

window.cl.Swiper = Swiper;