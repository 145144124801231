export default class Dispatcher {

    /**
     *
     * @type {Swiper}
     */
    swiper = null;

    autoPlayStep = null;

    constructor(swiper) {
        this.swiper = swiper;
    }

    updateDraggableObjectWidth() {

        let itemWidthSum = 0, index;

        const items = this.swiper.items;

        for(index in items)
        {
            items[index].width = items[index].domObj.offsetWidth;
            items[index].height = items[index].domObj.offsetHeight;

            itemWidthSum += items[index].width;
        }

        this.swiper.draggableObj.style.width = itemWidthSum + 'px';
    }

    getSelectedTranslationX() {

        if(!this.swiper.domObj) return 0;

        let index, translationX = 0;

        for(index in this.swiper.items)
        {
            if(parseInt(index) === parseInt(this.swiper.selectedIndex)) break;

            translationX += parseInt(getComputedStyle(this.swiper.items[index].domObj).width);
        }

        if(this.swiper.items[this.swiper.selectedIndex] !== null && this.swiper.items[this.swiper.selectedIndex] !== undefined ) {
            translationX += Math.round(parseInt(getComputedStyle(this.swiper.items[this.swiper.selectedIndex].domObj).width) / 2);
        }

        return ((this.swiper.domObj.offsetWidth / 2) - translationX);
    }

    getSelectedItemWidth()
    {
        return this.swiper.items[this.swiper.selectedIndex].width;
    }

    getX(_event) {

        return _event.clientX - this.swiper.draggableObj.getBoundingClientRect().left;
    }

    getRelativeX(_event) {

        let x;
        const itemWidth = this.getSelectedItemWidth();

        if(_event.deltaY !== undefined)
        {
            if(_event.deltaY !== 0)
            {
                x = parseInt(this.swiper.draggableObj.getAttribute('data-transform-x')) - (_event.deltaY > 0 ? itemWidth * -0.9 : itemWidth * 0.9);
            }
            else if(_event.deltaX !== 0)
            {
                x = parseInt(this.swiper.draggableObj.getAttribute('data-transform-x')) - (_event.deltaX < 0 ? itemWidth * -0.9 : itemWidth * 0.9);
            }
        }
        else
        {
            x = (_event.clientX - this.swiper.draggableObj.parentNode.getBoundingClientRect().left - this.swiper.draggableObj.getAttribute('data-dragdrop-x'));
        }

        if(x > this.swiper.draggableObj.getAttribute('data-dragdrop-min-x'))
        {
            return this.swiper.draggableObj.getAttribute('data-dragdrop-min-x');
        }
        else if(x < this.swiper.draggableObj.getAttribute('data-dragdrop-max-x'))
        {
            return this.swiper.draggableObj.getAttribute('data-dragdrop-max-x');
        }

        return x;
    }

    getSelectedIndex() {

        const center = (this.swiper.itemWidth === 1 ? this.swiper.domObj.offsetWidth / 4 * (this.relativeDirection > 0 ? 3 : 1) : this.swiper.domObj.offsetWidth / 2), items = this.swiper.items;
        let index, x = parseInt(this.swiper.draggableObj.getAttribute('data-transform-x'));

        for(index in items)
        {
            x += items[index].width;

            if(x > center)
            {
                return index;
            }
        }

        return items.length - 1;
    }

    getVisibleIndexes() {

        const items = this.swiper.items, visibleIndexes = {};

        if( this.swiper.draggableObj !== undefined &&  this.swiper.draggableObj !== null )
        {
            let index, x = parseInt(this.swiper.draggableObj.getAttribute('data-transform-x'));

            for(index in items)
            {
                x += items[index].width;

                if(x <= 0)
                {
                    continue;
                }

                if(x > items[index].width && x < this.swiper.domObj.offsetWidth)
                {
                    visibleIndexes[index] = 100;
                }
                else if(x > items[index].width)
                {
                    visibleIndexes[index] = Math.abs((x - items[index].width - this.swiper.domObj.offsetWidth) / items[index].width * 100);
                }
                else
                {
                    visibleIndexes[index] = x / items[index].width * 100;
                }

                if(x >= this.swiper.domObj.offsetWidth)
                {
                    break;
                }
            }
        }

        return visibleIndexes;
    }

    getMinTranslationX() {
        return (this.swiper.domObj.offsetWidth - this.swiper.items[0].width) / 2;
    }

    getMaxTranslationX() {
        return -(this.swiper.draggableObj.offsetWidth - ((this.swiper.items[this.swiper.items.length - 1].width + this.swiper.draggableObj.parentNode.offsetWidth) / 2));
    }

    getMinIndex() {
        return 0;
    }

    getMaxIndex() {
        return this.swiper.items.length - 1;
    }

    getMinScope() {
        return this.swiper.items[0].width * 0.5;
    }

    getMaxScope() {
        return this.swiper.items[this.swiper.items.length - 1].width * 0.5;
    }

    startDragDrop(_event) {

        if(document.body.getAttribute('data-editor'))
        {
            return ;
        }

        this.disableAutoPlay();
        this.updateDraggableObjectWidth();

        this.relativeXStart = _event.clientX - this.swiper.draggableObj.parentNode.getBoundingClientRect().left;

        this.swiper.draggableObj.setAttribute('data-dragdrop-x', this.getX(_event));
        this.swiper.draggableObj.setAttribute('data-dragdrop-min-x', this.getMinTranslationX() + this.getMinScope());
        this.swiper.draggableObj.setAttribute('data-dragdrop-max-x', this.getMaxTranslationX() - this.getMaxScope());

        cl.basic.addClassName(this.swiper.draggableObj, 'dragdrop-active');

        cl.event.addListener(this.swiper.draggableObj, 'mousemove',  this.swiper.moveDragDrop);
        cl.event.addListener(this.swiper.draggableObj, 'touchmove',  this.swiper.moveDragDropTouch, {passive: true});
        cl.event.addListener(this.swiper.draggableObj, 'touchend',  this.swiper.endDragDrop, {passive: true});
        cl.event.addListener(this.swiper.draggableObj, 'mouseup',  this.swiper.endDragDrop);
        cl.event.addListener(this.swiper.draggableObj, 'mouseout',  this.swiper.endDragDrop);
        cl.event.addListener(this.swiper.draggableObj, 'mouseleave',  this.swiper.endDragDrop);

        cl.event.stopEvent(_event);
    }

    moveDragDrop(_event) {

        const newTranslateX = this.getRelativeX(_event);

        this.relativeDirection = (this.relativeXStart - (_event.clientX - this.swiper.draggableObj.parentNode.getBoundingClientRect().left)) * 100 / this.swiper.draggableObj.parentNode.offsetWidth;

        this.swiper.draggableObj.style.transform = 'translateX(' + newTranslateX + 'px)';
        this.swiper.draggableObj.setAttribute('data-transform-x', newTranslateX);
        this.swiper.draggableObj.setAttribute('data-diff-x', this.getX(_event));
        this.swiper.draggableObj.setAttribute('data-transform-no-click', 1);

        this.swiper.selectedIndex = this.getSelectedIndex();
        this.swiper.visibleIndexes = this.getVisibleIndexes();

        cl.event.stopEvent(_event);
    }

    endDragDrop(_event) {

        this.disableAutoPlay();

        cl.event.removeListener(this.swiper.draggableObj, 'mousemove', this.swiper.moveDragDrop);
        cl.event.removeListener(this.swiper.draggableObj, 'touchmove', this.swiper.moveDragDropTouch);
        cl.event.removeListener(this.swiper.draggableObj, 'touchend', this.swiper.endDragDrop);
        cl.event.removeListener(this.swiper.draggableObj, 'mouseup', this.swiper.endDragDrop);
        cl.event.removeListener(this.swiper.draggableObj, 'mouseout',this.swiper.endDragDrop);
        cl.event.removeListener(this.swiper.draggableObj, 'mouseleave',this.swiper.endDragDrop);

        if(this.swiper.draggableObj.getAttribute('data-transform-no-click') === null)
        {
            if(typeof this.swiper.onClick === 'function')
            {
                this.swiper.onClick(this.swiper.selectedIndex);
            }
        }

        this.swiper.draggableObj.removeAttribute('data-transform-no-click');
        this.swiper.draggableObj.removeAttribute('data-dragdrop-x');
        cl.basic.removeClassName(this.swiper.draggableObj, 'dragdrop-active');

        this.swiper.scrollTo();

        //needed for clicking links
        //cl.event.stopEvent(_event);
    }

    startHorizontalScroll(_event) {

        this.updateDraggableObjectWidth();

        this.swiper.draggableObj.setAttribute('data-dragdrop-x', this.getX(_event));
        this.swiper.draggableObj.setAttribute('data-dragdrop-min-x', this.getMinTranslationX() + this.getMinScope());
        this.swiper.draggableObj.setAttribute('data-dragdrop-max-x', this.getMaxTranslationX() - this.getMaxScope());

        cl.event.addListener(this.swiper.draggableObj, 'wheel', this.swiper.moveHorizontalScroll);
        cl.event.addListener(this.swiper.draggableObj, 'mouseout',this.swiper.endHorizontalScroll);
        cl.event.addListener(this.swiper.draggableObj, 'mouseleave',this.swiper.endHorizontalScroll);

        cl.event.stopEvent(_event);
    }

    moveHorizontalScroll(_event) {

        const newTranslateX = this.getRelativeX(_event);

        this.swiper.draggableObj.style.transform = 'translateX(' + newTranslateX + 'px)';
        this.swiper.draggableObj.setAttribute('data-transform-x', newTranslateX);

        if(this.swiper.selectedIndex === this.getSelectedIndex())
        {
            if(this.scrollSelectCount === undefined)
            {
                this.scrollSelectCount = 0;
            }

            this.scrollSelectCount++;

            if(this.scrollSelectCount > 5)
            {
                return;
            }
        }
        else
        {
            this.scrollSelectCount = 0;
        }

        this.swiper.selectedIndex = this.getSelectedIndex();
        this.swiper.visibleIndexes = this.getVisibleIndexes();

        cl.event.stopEvent(_event);
    }

    endHorizontalScroll(_event) {

        cl.event.removeListener(this.swiper.draggableObj, 'wheel', this.swiper.moveHorizontalScroll);
        cl.event.removeListener(this.swiper.draggableObj, 'mouseout', this.swiper.endHorizontalScroll);
        cl.event.removeListener(this.swiper.draggableObj, 'mouseleave',this.swiper.endHorizontalScroll);

        this.swiper.draggableObj.removeAttribute('data-dragdrop-x');
        cl.basic.removeClassName(this.swiper.draggableObj, 'dragdrop-active');

        this.swiper.scrollTo();

        cl.event.stopEvent(_event);
    }

    disableAutoPlay(_event)
    {
        clearInterval(this.autoPlayIntervall);
    }

    enableAutoPlay(_event)
    {
        if(this.autoPlayStep === null)
        {
            this.autoPlayStep = 1;
        }

        if(this.autoPlayIntervall)
        {
            clearInterval(this.autoPlayIntervall);
        }

        this.autoPlayIntervall = setInterval(() => {

            if(this.swiper.selectedIndex + this.autoPlayStep >= this.swiper.items.length)
            {
                this.autoPlayStep = -1;
            }
            else if(this.swiper.selectedIndex + this.autoPlayStep < 0)
            {
                this.autoPlayStep = 1;
            }

            this.swiper.scrollTo(parseInt(this.swiper.selectedIndex) + parseInt(this.autoPlayStep));

        }, this.swiper.autoplay);

        cl.event.stopEvent(_event);
    }
};