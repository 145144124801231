export default class Builder {

    /**
     * @type {Swiper}
     */
    swiper = null;

    constructor(swiper) {

        this.swiper = swiper;
    }

    buildSwiper() {

        this.swiper.domObj.innerHTML = "";

        let index;

        const mask = cl.basic.createDomElement({
            tagName: 'DIV',
            className: 'mask'
        });

        if(this.swiper.navigationButtons)
        {
            const nextButton = cl.basic.createDomElement({
                    tagName: 'button',
                    className: 'nav-btn next',
                    ariaLabel: 'Right Align',
                    type: 'button'
                }),
                beforeButton = cl.basic.createDomElement({
                    tagName: 'button',
                    className: 'nav-btn before',
                    ariaLabel: 'Left Align',
                    type: 'button'
                });

            cl.event.addListener(nextButton, 'click',() => {
                this.swiper.scrollTo(parseInt(this.swiper.selectedIndex) + (this.swiper.navigationReverse ? 1 : -1));
            });

            cl.event.addListener(beforeButton, 'click',  () => {
                this.swiper.scrollTo(parseInt(this.swiper.selectedIndex) + (this.swiper.navigationReverse ? -1 : 1));
            });

            this.swiper.domObj.appendChild(nextButton);
            this.swiper.domObj.appendChild(beforeButton);
        }

        const items = this.swiper.items;

        this.swiper.domObj.appendChild(mask);

        this.swiper.draggableObj = cl.basic.createDomElement({
            tagName: 'DIV',
            className: 'list draggable',
            children: items
        });

        mask.appendChild(this.swiper.draggableObj);

        this.ready = false;

        for(index in items)
        {
            this.prepareItem(items[index]);
        }

        this.setReady();
    }

    prepareItem(_item)
    {
        if(typeof this.swiper.prpearedItemWidth === "number")
        {
            _item.domObj.style.width = this.swiper.prpearedItemWidth + 'px';
        }
        else
        {
            _item.domObj.style.width = this.swiper.prpearedItemWidth;
        }

        if(typeof this.swiper.prpearedItemHeight === "number")
        {
            _item.domObj.style.height = this.swiper.prpearedItemHeight + 'px';
        }
        else
        {
            _item.domObj.style.height = this.swiper.prpearedItemHeight;
        }

        this.prepareImages(_item.domObj);
    }

    prepareImages(_itemDomObj)
    {
        const images = _itemDomObj.querySelectorAll('[data-src]'),
            self = this,
            itemAlignment = this.swiper.itemAlignment;
        let i;

        for(i = 0; i < images.length; ++i)
        {
            if(images[i].dataset.src.indexOf('max') < 0 && images[i].dataset.src.indexOf('cut') < 0) continue;

            images[i].className = '';

            if(images[i].src.indexOf('data:') === -1)
            {
                images[i].src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
            }

            if(this.swiper.itemImageFit)
            {
                images[i].dataset.height = _itemDomObj.offsetHeight * this.swiper.itemImageSize / 100;
                images[i].dataset.width = _itemDomObj.offsetWidth * this.swiper.itemImageSize / 100;
                images[i].dataset.src = images[i].dataset.src.replace('/max', '/cut');
            }
            else if(itemAlignment === this.swiper.options.itemAlignment.height)
            {
                images[i].dataset.height = _itemDomObj.offsetHeight * this.swiper.itemImageSize / 100;
                images[i].style.height = images[i].dataset.height + 'px';
            }
            else if(itemAlignment === this.swiper.options.itemAlignment.width)
            {
                images[i].dataset.width = _itemDomObj.offsetWidth * this.swiper.itemImageSize / 100;
                images[i].style.width = images[i].dataset.width + 'px';
            }
        }
    }

    setReady () {

        this.swiper.updateDraggableObjectWidth();
        this.swiper.setLoaded();
    }
}